/**
 * Products page
 *
 * @author Dominique Rau [domi.github@gmail.com](mailto:domi.github@gmail.com)<br/>
 * @version 0.0.1
 */
import { graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import * as React from 'react';

import { thingosColors } from '../../../shared/src/Theme/theme';
import Container, { ColorContainer, Column, Row } from '../components/Container';
import { Page } from '../components/Page';
import { SideBySide } from '../components/SideBySide';
import { Body, Footnote, HeaderH2, Mark } from '../components/Typography';
import { Wave } from '../components/Wave';
import { IndexLayout } from '../layouts';

const ProductsPage = () => {
	const { t } = useTranslation('products');

	return (
		<IndexLayout>
			<Page>
				<ColorContainer backgroundColor={thingosColors.background.light}>
					<Container>
						<HeaderH2>{t('products.layer.title')}</HeaderH2>
						<Row>
							<SideBySide>
								<Body style={{ flex: 1 }}>{t('products.layer.text1a')}</Body>
								<Body style={{ flex: 1 }}>{t('products.layer.text1b')}</Body>
							</SideBySide>
						</Row>
						<StaticImage
							src="../content/demo-technology-neutral-layer.png"
							alt="Technology neutral layer"
						/>
					</Container>
				</ColorContainer>
				<Wave color={thingosColors.background.dark} />
				<ColorContainer
					backgroundColor={thingosColors.background.dark}
					style={{ paddingBottom: '0px' }}
				>
					<Container>
						<HeaderH2>{t('products.hubs.title')}</HeaderH2>
						<SideBySide reverseStackOrder>
							<Column flex>
								<Body>
									{t('products.hubs.text1a')} <br /> <br /> {t('products.hubs.text1b')}
								</Body>
							</Column>
							<StaticImage
								src="../content/products-hub-hand.png"
								alt="Connect Hub"
								objectFit="contain"
								height={600}
							/>
						</SideBySide>
					</Container>
				</ColorContainer>
				<Wave color={thingosColors.background.dark} flipped="vertical" />
				<ColorContainer>
					<Container>
						<HeaderH2>{t('products.apps.title')}</HeaderH2>
						<SideBySide>
							<Column style={{ padding: 24 }}>
								<StaticImage
									src="../content/env-smart-environment.png"
									alt="Smart Environment App"
									objectFit="contain"
								/>
							</Column>
							<Column flex>
								<Body>
									{t('products.apps.text1a')}
									<br />
									<br />
									<Mark>{t('products.apps.mark')}</Mark> {t('products.apps.text1b')}
									<br />
									<br />
									{t('products.apps.text1c')}
								</Body>
							</Column>
						</SideBySide>
					</Container>
				</ColorContainer>
				<Wave color={thingosColors.background.dark} />
				<ColorContainer backgroundColor={thingosColors.background.dark}>
					<Container>
						<HeaderH2>{t('products.appstore.title')}</HeaderH2>
						<SideBySide reverseStackOrder>
							<Column flex>
								<Body>
									{t('products.appstore.text1a')}
									<Mark>{t('products.appstore.mark1')}</Mark>
									{t('products.appstore.text1b')}
									<br />
									<br />
									{t('products.appstore.text1c')}
									<Mark>{t('products.appstore.mark2')}</Mark>
									{t('products.appstore.text1d')}
								</Body>
							</Column>
							<StaticImage
								src="../content/company-appstore-new.png"
								alt="App Store"
								objectFit="contain"
							/>
						</SideBySide>
					</Container>
				</ColorContainer>
				<Wave color={thingosColors.background.dark} flipped="vertical" />
				<ColorContainer>
					<Container>
						<HeaderH2>{t('products.xde.title')}</HeaderH2>
						<SideBySide>
							<StaticImage src="../content/demo-sdk.png" alt="SDK" objectFit="contain" />
							<Column flex>
								<Body>
									{t('products.xde.text1a')} <Mark>{t('products.xde.mark1')}</Mark>
									{t('products.xde.text1b')} <Mark>{t('products.xde.mark2')}</Mark>{' '}
									{t('products.xde.text1c')} (
									<a href="https://www.youtube.com/watch?v=_6gulCwetKI">Video</a>)
									{t('products.xde.text1d')}
									<br />
									<br />
									{t('products.xde.text1e')} <Mark>{t('products.xde.mark3')}</Mark>
									{t('products.xde.text1f')}
								</Body>
							</Column>
						</SideBySide>
					</Container>
				</ColorContainer>
				<Wave color={thingosColors.background.dark} />
				<ColorContainer backgroundColor={thingosColors.background.dark}>
					<Container>
						<HeaderH2>{t('products.thirdParty.title')}</HeaderH2>
						<SideBySide reverseStackOrder>
							<Column flex>
								<Body>
									<p>
										{t('products.thirdParty.text1a')} <Mark>{t('products.thirdParty.mark1')}</Mark>
										{t('products.thirdParty.text1b')}
									</p>
									<p>
										{t('products.thirdParty.text1c')} <Mark>{t('products.thirdParty.mark2')}</Mark>
										{t('products.thirdParty.text1d')}
									</p>
									<p>
										{t('products.thirdParty.text1e')} <Mark>{t('products.thirdParty.mark3')}</Mark>
										{t('products.thirdParty.text1f')}
									</p>
								</Body>
							</Column>
							<Column style={{ paddingTop: 100 }}>
								<StaticImage
									src="../content/demo-offtheshelf.png"
									alt="Off the shelf devices"
									objectFit="contain"
								/>
							</Column>
						</SideBySide>
					</Container>
				</ColorContainer>
				<Wave color={thingosColors.background.dark} flipped="vertical" />
				<ColorContainer>
					<Container>
						<HeaderH2>{t('products.embedded.title')}</HeaderH2>
						<SideBySide>
							<StaticImage
								src="../content/products-embedded-device.png"
								alt="Embedded Device"
								objectFit="contain"
							/>

							<Column flex>
								<Body>
									<p>
										{t('products.embedded.text1a')} <Mark>{t('products.embedded.mark1')}</Mark>{' '}
										{t('products.embedded.text1b')}
									</p>
									<p>{t('products.embedded.text1c')}</p>
								</Body>
							</Column>
						</SideBySide>
					</Container>
				</ColorContainer>
				<Wave color={thingosColors.background.dark} />
				<ColorContainer backgroundColor={thingosColors.background.dark}>
					<Container>
						<HeaderH2>{t('products.wearable.title')}</HeaderH2>
						<SideBySide reverseStackOrder>
							<Column flex>
								<Body>
									<p>
										{t('products.wearable.text1a')} <Mark>{t('products.wearable.mark1')}</Mark>{' '}
										{t('products.wearable.text1b')}
										<Mark>{t('products.wearable.mark2')}</Mark>
										{t('products.wearable.text1c')} <Mark>{t('products.wearable.mark3')}</Mark>
										{t('products.wearable.text1d')}
									</p>
									<p>
										{t('products.wearable.text1e')} <Mark>{t('products.wearable.mark4')}</Mark>
										{t('products.wearable.text1f')}
									</p>
									<p>
										{t('products.wearable.text1g')}
										<Mark>{t('products.wearable.mark5')}</Mark>.
									</p>
								</Body>
							</Column>
							<StaticImage
								src="../content/demo-wearable-devices.png"
								alt="Wearable devices"
								objectFit="contain"
							/>
						</SideBySide>
						<Footnote style={{ padding: '32px 0' }}>{t('products.footnote')}</Footnote>
					</Container>
				</ColorContainer>
			</Page>
		</IndexLayout>
	);
};

export const query = graphql`
	query ($language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
	}
`;

export default ProductsPage;
